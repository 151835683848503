<script setup>
import { computed, ref, onMounted } from "vue";

const props = defineProps({
  artists: {
    type: Array,
    default: () => []
  }
});

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
const activeLetter = ref(""); // Track the currently active letter
const showLeftGradient = ref(false); // State for showing left gradient
const showRightGradient = ref(false); // State for showing right gradient

const onLetterClick = (letter) => {
  // If there are no artists for the selected letter, ignore the click event
  if (!hasArtistsForLetter(letter)) {
    return;
  }

  // If there's already an active letter, remove its active class
  if (activeLetter.value) {
    const previousElement = document.getElementById(`letter-${activeLetter.value}`);
    if (previousElement) {
      previousElement.classList.remove("active");
    }
  }

  // Set the new active letter
  activeLetter.value = letter;

  // Add the active class to the new letter
  const currentElement = document.getElementById(`letter-${letter}`);
  if (currentElement) {
    currentElement.classList.add("active");
  }

  scrollToLetter(letter); // Scroll to the selected letter section
  console.log("Letter clicked", letter); // Log the clicked letter
};

const hasArtistsForLetter = (letter) => {
  return props.artists.some((artist) => artist.letter === letter);
};

const scrollToLetter = (letter) => {
  const element = document.getElementById(letter);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const artistsByLetter = computed(() => {
  const groupedArtists = {};
  for (const artist of props.artists) {
    if (!groupedArtists[artist.letter]) {
      groupedArtists[artist.letter] = [];
    }
    groupedArtists[artist.letter].push(artist);
  }
  return groupedArtists;
});

const updateGradientVisibility = (event) => {
  const target = event.target;

  showLeftGradient.value = target.scrollLeft > 0;
  showRightGradient.value = target.scrollWidth - target.clientWidth > target.scrollLeft;
};

onMounted(() => {
  const alphabetList = document.querySelector(".alphabet-list");

  if (alphabetList) {
    alphabetList.addEventListener("scroll", updateGradientVisibility);
    // Initial check to see if gradients are needed
    updateGradientVisibility({ target: alphabetList });
  }
});
</script>

<template>
  <!-- Mobile only banner - @TODO a better way when dealing with changing sticky targets -->
  <section class="w-full pt-f8 px-f5 bg-slate items-center justify-between tb:hidden">
    <h2 class="text-white text-f-3xl-7xl font-serif leading-10">Artists</h2>
  </section>

  <section class="letter-wrapper w-full bg-slate items-center justify-between mb-f4 tb:px-f5 tb:py-f8 tb:inline-flex sticky top-0 z-10">
    <h2 class="text-white text-f-3xl-7xl font-serif leading-10 hidden tb:block">Artists</h2>
    <div
      class="alphabet-list items-start w-full inline-flex overflow-y-hidden overflow-x-auto scroll-smooth px-f5 py-f8 justify-start gap-f6 hide-scrollbar tb:gap-f2 tb:p-0 tb:w-auto"
    >
      <a
        v-for="letter in alphabet"
        :id="'letter-' + letter"
        :key="letter"
        class="artist-letter relative"
        :class="[hasArtistsForLetter(letter) ? 'default text-white cursor-pointer' : 'disabled cursor-default text-neutral-40']"
        @click="onLetterClick(letter)"
      >
        {{ letter }}
      </a>
    </div>

    <!-- Gradient overlays -->
    <div v-if="showLeftGradient" class="absolute top-0 left-0 w-10 h-full bg-gradient-to-r from-slate via-transparent to-transparent pointer-events-none"></div>
    <div
      v-if="showRightGradient"
      class="absolute top-0 right-0 w-10 h-full bg-gradient-to-l from-slate via-transparent to-transparent pointer-events-none"
    ></div>
  </section>

  <section class="tb:grid tb:grid-cols-2 lg:grid-cols-3 gap-f8 pb-f8 px-f5">
    <div v-for="(artistsGroup, letter) in artistsByLetter" :id="letter" :key="letter" class="pt-f4 scroll-mt-f25">
      <h2 class="text-neutral-70 text-2xl leading-tight mb-4 pb-4 border-b-[1px]">{{ letter }}</h2>
      <ul>
        <li v-for="artist in artistsGroup" :key="artist.url" class="leading-tight mb-4">
          <a :href="artist.url" class="text-neutral-50 text-base hover:text-neutral-70 pb-1 cursor-pointer hover-underline-with-transition">{{
            artist.name
          }}</a>
        </li>
      </ul>
    </div>
  </section>
</template>

<style scoped>
.letter-wrapper {
  scroll-snap-type: x mandatory;
}

.artist-letter.default {
  &::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: translateY(2px);
    transition:
      transform 0.3s ease,
      opacity 0.3s ease;
  }

  &.active,
  &:hover {
    &::after {
      content: "";
      opacity: 1;
      transform: translateY(0);
      transition:
        transform 0.3s ease,
        opacity 0.3s ease;
    }
  }
}
</style>
